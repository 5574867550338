class footerSectionComponent extends Component {

    static name() {
        return "footerSectionComponent";
    }

    static componentName() {
        return "footerSectionComponent";
    }

    data() {
        return {year: new Date().getFullYear()};
    }

    getComputed() {
        return {
            menuTitle:function(){
                return this.$store.getters.menuTitle;
            },
            menuSubTitle:function(){
                return this.$store.getters.menuSubTitle;
            },
            mustRender:function(){
                return this.$store.getters.settingsIsLoaded;
            },
            enableLK:function () {
                return this.$store.state.setting.EnableLK;
            },
            linkLK: function (){
                let link = "#";
                if(this.$store.state.setting.LKLink)
                    link = this.$store.state.setting.LKLink;
                return link;
            },
            enableIG:function () {
                return this.$store.state.setting.EnableIG;
            },
            linkIG: function (){
                let link = "https://instagram.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.IGLink;
                return link;
            },
            enableFB:function () {
                return this.$store.state.setting.EnableFB;
            },
            linkFB: function (){
                let link = "https://facebook.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.FBLink;
                return link;
            },
            enableTW:function () {
                return this.$store.state.setting.EnableTW;
            },
            linkTW: function (){
                let link = "https://twitter.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.TWLink;
                return link;
            },
            footerTemplateCode: function (){
                return this.$store.state.setting.FooterTemplate;
            },
        };
    }

    getTemplate() {
        return `<footer class="container-fluid" v-if="mustRender">
                 <template v-if="footerTemplateCode">
                    <htmlSectionComponent :templateCode="footerTemplateCode" />
                 </template>
                 <template v-else>
                    <div class="row justify-content-center py-3">
                         <div class="col-12 col-md-6 text-center text-md-left">
                            Copyright © {{year}} <a href="#" class="brand">{{menuTitle}}</a> All rights reserved.
                        </div>
                        <div class="col-12 col-md-6 text-center text-md-right mt-2 mt-md-0">
                            <span class="right">with <i class="icon fas fa-heart"></i> by <a href="https://oppen.io/" target="_blank" class="brand">öppen</a></span>
                        </div>
                    </div>
                </template>
            </footer>`;
    }
}

footerSectionComponent.registerComponent();